<template>
    <main>
        <div class="container-fluid">
            <div v-if="isOnline()">
                <h1>{{DachziegelData.data.options.lang.general_error}}</h1>
                <p>
                    {{DachziegelData.data.options.lang.general_error_offline}}
                </p>
            </div>
            <!--
            <div v-show="backBtnHis" class="go-back">
                <div class="container-fluid">
                    <a @click="backBtn">
                        <svg class="icon" enable-background="new 0 0 1000 1000" viewBox="0 0 1000 1000"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="m794 69.4-63.4-59.4-524.6 490 524.6 490 63.4-59.1-460.9-430.9z"/>
                        </svg>
                        <span class="go-back__label">Zurück</span>

                    </a>
                </div>
            </div>
            -->
            <iframe v-if="isOnline()" id="iframeView" :src="this.$route.query.iframe" @load="onLoadIframe" @onload="onLoadIframe" @error="onLoadIframeError" ></iframe>
        </div>
    </main>
</template>
<script>
    import {DachziegelDataMixin} from "../mixins/DachziegelDataMixin";
    export default {
        name: 'iframeView',
        mixins: [DachziegelDataMixin],
        mounted() {
            this.$DKFuctions.headerShowGoBackHide()
            if (navigator.onLine) {
                this.$DKFuctions.loaderShow()
            }
        },
        methods: {
            backBtn: function () {
                if (history.length > 2) {
                    history.back();
                } else {
                    history.replaceState(null, null, this.$route.currentRoute)
                }
                return false
            },
             backBtnHis: function () {
                return history.length <= 2
            },
            isOnline: function () {
               return  navigator.onLine
            },
            onLoadIframe: function () {
                this.$DKFuctions.loaderHide()
            },
            onLoadIframeError: function () {
                this.$DKFuctions.loaderHide()
            }
        }
    }
</script>
<style>
    iframe{
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
    }
</style>
